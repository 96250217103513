const staff = [
    {
        id: '1',
        name: 'Adriana Carrara',
        role: 'Directora',
        img: "images/staff/adriana-carrara.png",
        directive: true,
    },
    {
        id: '2',
        name: 'Vanesa Di Fiore',
        role: 'Vice Directora',
        img: "images/staff/vanesa-difiore.png",
        directive: true,
    },
    {
        id: '3',
        name: 'Emanuel Pontoni',
        role: 'Profesor de Matemática',
        img: "images/staff/emanuel-pontoni.png",
        directive: false,
    },
    {
        id: '4',
        name: 'Vanina Grisanti',
        role: 'Profesora de Artes Visuales',
        img: "images/staff/vanina-grisanti.png",
        directive: false,
    },
    {
        id: '5',
        name: 'Soledad Giraudo',
        role: 'Docente y Preceptora',
        img: "images/staff/soledad-giraudo.png",
        directive: false,
    },
    /*{
        id: '6',
        name: 'Jorge Abregu',
        role: 'Educación Tecnológica',
        img: "images/staff/jorge-l-abregú.jpg",
        directive: false,
    },*/
    {
        id: '7',
        name: 'Noelia López',
        role: 'Preceptora',
        img: "images/staff/noelia-lópez.png",
        directive: false,
    },
    {
        id: '8',
        name: 'Jessica Lorena Fouillet',
        role: 'Departamento de Lenguas',
        img: "images/staff/jessica-lorena-fouillet.png",
        directive: false,
    },
    {
        id: '9',
        name: 'Marisa Analia Libaak',
        role: 'Educación Física',
        img: "images/staff/marisa-analia-libaak.png",
        directive: false,
    },
    {
        id: '10',
        name: 'Maria Belen Becerra',
        role: 'Departamento de Matemática',
        img: "images/staff/maria-belen-becerra.png",
        directive: false,
    },
    {
        id: '11',
        name: 'Marina Penalba',
        role: 'Secretaria',
        img: "images/staff/marina-penalba.png",
        directive: false,
    },
    {
        id: '12',
        name: 'Mariela Torres',
        role: 'Docente',
        img: "images/staff/mariela-torres.png",
        directive: false,
    },
    {
        id: '13',
        name: 'Evelyn Savini',
        role: 'Prof. de Lengua y Literatura',
        img: "images/staff/evelyn-savini.png",
        directive: false,
    },
    {
        id: '14',
        name: 'Carla Sartori',
        role: 'Docente y MEP',
        img: "images/staff/carla-sartori.png",
        directive: false,
    },
    {
        id: '15',
        name: 'Alejandra Latorre',
        role: 'Prof. Geografía',
        img: "images/staff/alejandra-latorre.png",
        directive: false,
    },
    {
        id: '16',
        name: 'Brenda Podversich',
        role: 'Biología y Química',
        img: "images/staff/brenda-podversich.png",
        directive: false,
    }
];
export default staff;